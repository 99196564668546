<template src="./documents.html"></template>
<style src="./documents.scss" lang="scss" scoped></style>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters, companyStateRoutine } from '@/store/modules/company/routines'
import { getDocumentsList, setJourneyDocumentsStatus } from '@/api/document'
import { toastDefaultOptions } from '@/config/vue-toast'
import ProductRequiredDocuments from '@/components/ProductRequiredDocuments'
import JourneyHeader from '../journeyHeader'
import { amplitudeEvents } from '@/utils/constants'
import { sendBasicEventToAmplitude, journeyStepsButtonAmplitudeEvent } from '@/utils/utils'
import steps from '@/features/journey/steps'

export default {
  name: 'JourneyDocuments',
  components: {
    JourneyHeader,
    ProductRequiredDocuments
  },
  mixins: [steps],
  data() {
    return {
      documentsList: null,
      requiredDocuments: [
        { label: '6 Months Company Bank Statements', type: 'Financials', title: '6 Months Company Bank Statements', amplitudeEvent: 'UPLOAD_DOCUMENTS_BANK_STATEMENTS' },
        { label: 'Latest Management Accounts', type: 'Financials', title: 'Management Accounts', amplitudeEvent: 'UPLOAD_DOCUMENTS_MANAGEMENT_ACCOUNTS' },
        { label: 'Last 2 Year Filed Accounts', type: 'Financials', title: '2 Years Filed Accounts', amplitudeEvent: 'UPLOAD_DOCUMENTS_FIELD_ACCOUNTS' },
        { label: `${this.$dictionary.vat} Returns`, type: 'Financials', title: `${this.$dictionary.vat} Returns`, amplitudeEvent: 'UPLOAD_DOCUMENTS_VAT_RETURNS' },
        { label: "Copy of All Directors' ID", type: 'Identification', title: 'Proof of Identity', amplitudeEvent: 'UPLOAD_DOCUMENTS_DIRECTORS_ID' },
        {
          label: "All Directors' Proof of Address (Dated within last 3 months)",
          type: 'Identification',
          title: 'Proof of Address (Dated within last 3 months)',
          amplitudeEvent: 'UPLOAD_DOCUMENTS_ADDRESS_PROOF'
        }
      ],
      sendBasicEventToAmplitude,
      amplitudeEvents,
      journeyStepsButtonAmplitudeEvent
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    }),
    isPrivateLimitedCompany() {
      return this.company.companyStructures === 'Private Limited Company'
    },
    requiredDocumentsList() {
      if (!this.documentsList) return []
      const { documentsList, requiredDocuments } = this
      return requiredDocuments.map(document => {
        const uploadedDocuments = documentsList.filter(doc => doc.title === document.title && doc.type === document.type)
        const uploadedDocumentNames = uploadedDocuments.map(doc => doc.name)
        const isDocumentUploaded = uploadedDocuments.length > 0
        const key = `${document.type}-${document.title}`
        return {
          ...document,
          key,
          uploadedDocumentNames,
          isDocumentUploaded
        }
      })
    },
    hasAllRequiredDocuments() {
      return this.requiredDocumentsList.reduce((acc, doc) => acc && doc.isDocumentUploaded, true)
    }
  },
  methods: {
    ...mapActions({
      storeRegistrationState: companyStateRoutine.TRIGGER
    }),
    init() {
      this.loadDocumentsList()
    },
    loadDocumentsList() {
      return getDocumentsList(this.companyId)
        .then(res => {
          this.documentsList = res.data
        })
        .catch(e => {
          this.documentsList = []
        })
    },
    documentsListChangeHandler() {
      this.loadDocumentsList()
    },
    skipClickHandler() {
      this.$modal.show('journeyDocumentsModal')
      sendBasicEventToAmplitude(this.$ma, journeyStepsButtonAmplitudeEvent('UPLOAD_DOCUMENTS_SKIP'))
    },
    async skipAnywayClickHandler(route) {
      await this.updateDocumentsStepStatus('Skip')
      await this.$router.push(route)
    },
    async nextStepClickHandler(route) {
      await this.updateDocumentsStepStatus('Completed')
      await this.$router.push(route)
    },
    async updateDocumentsStepStatus(state) {
      try {
        const { companyId } = this
        await setJourneyDocumentsStatus({ companyId, state })
        await this.storeRegistrationState(companyId)
      } catch (e) {
        this.$toasted.show('Unable skip documents step', { ...toastDefaultOptions, type: 'error', icon: 'error' })
      }
    }
  },
  beforeMount() {
    this.init()
  }
}
</script>
