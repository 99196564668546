<template>
  <div class="progress-bar-with-steps" role="progressbar">
    <p class="progress-bar-label ui text">Step {{ currentStepIndex + 1 }} of {{ steps.length }}: {{ steps[currentStepIndex].description }}</p>
    <div class="progress-bar-steps">
      <div v-for="(step, index) in steps" :class="{ 'progress-bar-step': true, 'progress-bar-step--completed': index <= currentStepIndex }" :key="index"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBarWithSteps',
  props: {
    steps: {
      type: Array
    },
    currentStepIndex: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables';
.progress-bar-with-steps {
  margin-top: -15px;
  background: $color-white;
  .progress-bar-label {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 8px;
  }
  .progress-bar-steps {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 8px;
    box-shadow: 0 2px 6px 0 rgba(51, 51, 79, 0.06);

    .progress-bar-step {
      flex: 1;
      background-color: var(--color-primary-100);
      border: 1px solid $color-white;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
      &--completed {
        background-color: $color-primary;
      }
    }
  }
}
</style>
