<template>
  <ProgressBarWithSteps :steps="enabledSteps" :currentStepIndex="currentStepIndex" />
</template>

<script>
import ProgressBarWithSteps from '@/components/ProgressBarWithSteps'
import steps from '@/features/journey/steps'

export default {
  name: 'JourneyHeader',
  mixins: [steps],
  components: {
    ProgressBarWithSteps
  }
}
</script>
