import { mapGetters } from 'vuex'
import { authGetters } from '@/store/modules/auth/routines'
import config from '@/config/global'

export default {
  name: 'Steps Mixin',
  computed: {
    ...mapGetters({
      user: authGetters.USER
    }),
    userType() {
      return 'SME'
    },
    nextStepName() {
      if (this.nextStep && this.nextStep.name) {
        return this.nextStep.name
      }

      return this.nextStep
    },
    previousStepName() {
      if (this.previousStep && this.previousStep.name) {
        return this.previousStep.name
      }

      return this.previousStep
    },
    nextStep() {
      // steps are finished
      if (!config.whitelabel.journey.classicJourney[this.currentStepIndex + 1]) {
        return { name: 'dashboard' }
      }
      return config.whitelabel.journey.classicJourney[this.currentStepIndex + 1]
    },
    previousStep() {
      return config.whitelabel.journey.classicJourney[this.currentStepIndex - 1]
    },
    enabledSteps() {
      return config.whitelabel.journey.classicJourney.filter(step => step.enabled || (typeof step.enabled === 'string' && step.enabled.toLowerCase().includes(this.userType.toLowerCase())))
    },
    currentStepName() {
      return this.$route.name
    },
    currentStep() {
      return this.enabledSteps.find(({ name }) => name === this.currentStepName)
    },
    currentStepIndex() {
      return this.enabledSteps.findIndex(({ name }) => name === this.currentStepName)
    }
  }
}
